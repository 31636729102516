.project-card{
    display: flex;
    flex-direction: column;
    height: 35vh;
    width: 18vw;
    /* padding: 0rem 2rem; */
    padding: 2rem;
    margin: 2rem;
    border-radius: 10%;
    background-color: blanchedalmond;
}
h3{
    font-size: 3.4vh;
}
p{
    font-size: 2.7vh;
}
.top{
    font-size:1.4rem;
    height: 10vh;
}
.mid{
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
}
.bottom{
    display: flex;
    align-items: end;
    justify-content: end;
}
.icon{
    /* scale:1.5; */
    font-size:3.4vh;
    color: black;
}
