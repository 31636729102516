body{
    text-align: center;
}
.skills-main-container{
    /* height: 76vh; */
    /* display:flex;
    align-items: center;
    justify-content: center; */
    display: grid;
    padding:2rem;
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
    gap: 2rem;
}
.scard{
    /* padding:1.3rem ; */
    height:10rem;
    width:10rem;
    background-color: antiquewhite;
    border-radius: 10%;
    scale: 1;
    margin:2rem;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconContainer{
    /* padding-top: 1rem; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* border: 1px solid red; */
}
.sicon{
    scale:1.7;
}
.scard:hover{
    background-color: rgb(248, 196, 99);
    transform: scale(1.1);
}
Footer{
    /* bottom: 0%; */
    margin-top: 24vh;
}