h1{
    text-align: center;
}
.main-container{
    height: 84.2vh;
}
.main-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3vw;
    padding-left: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    /* padding: 5vh 5vh 15vh 5vh; */
    }
    @media (min-width: 768px) {
    .main-container {
    grid-template-columns: repeat(3, 1fr);
    }
    }
    @media (min-width: 1500px) {
    .main-container {
    grid-template-columns: repeat(3, 1fr);
    }
    }
    /* .project-card{
        display: flex;
        flex-direction: column;
    } */
    